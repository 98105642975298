.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card {
 width: 350px;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s; 
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.carda {
  width: 280px;
   /* Add shadows to create the "card" effect */
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   transition: 0.3s; 
   border-radius: 5px;
   display: block;
   margin-left: auto;
   margin-right: auto;
 }
 .iput {
  width: 250px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}
 .cardab {
  width: 320px;
   /* Add shadows to create the "card" effect */
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   transition: 0.3s; 
   border-radius: 5px;
   display: block;
   margin-left: auto;
   margin-right: auto;
 }
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
  margin: auto;
}
.fix{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.input{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 270px;
  height: 50px;
  resize: none;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  outline: none;

}
.contactinput{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 400px;
  height: 50px;
  resize: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  color: rgb(0, 0, 0);
  outline: none;

}
.textarea{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 555px;
  height: 200px;
  resize: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  color: rgb(0, 0, 0);
  outline: none;

}
.contacttextarea{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 400px;
  height: 300px;
  resize: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  color: rgb(0, 0, 0);
  outline: none;

}
.btn{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 400px;
  height: 50px;
  background-color: rgb(36, 31, 31);
  color: white;
  font-size: 18px;
  border-radius: 5px;
}
.btn:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}
.contactbtn{
  display: block;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 400px;
  height: 50px;
  border-radius: 5px;
  font-size: large;
  color: rgb(255, 255, 255);
  background-color: rgb(43, 40, 40);
  
}
.contactbtn:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.logo{
  text-align: center;
  font-size:40px;
  height: 100px;
  width: 500px;
  margin: auto;
  font-weight:900;
  color: rgb(0, 0, 0);
  border-radius: 5px;
}
.sendbutton{
  border: none;
  height: 25px;
  font-size: 15px;
  
}
.sendbutton:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.verbutton{
  width: 300px;
  height: 49px;
  border: none;
  background-color: rgb(36, 31, 31);
  color: white;
  font-size: large;
  border-radius: 5px;
}
.verbutton:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.ver1button{
  width: 250px;
  height: 49px;
  border: none;
  background-color: rgb(51, 45, 45);
  color: white;
  font-size: 17px;
}
.ver1button:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.ver1button:disabled{
  background-color: rgb(77, 74, 74);
}
.logout{
  border: none;
  height: 70px;
  width: 90px;
  font-size: 16px;
  border-radius: 5px;
}
.logout:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.all{
  width: 500px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  margin-left: 500px;
}
ul {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  margin-top: auto;
  background-color: #202022;
  list-style-type: none;
  padding: 0;
  width: 25%;
 color: #fff;
  height: 100%; /* Full height */
  position: fixed; /* Make it stick, even on scroll */
  overflow: auto; /* Enable scrolling if the sidenav has too much content */
}
li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
  color: #fff;
}

/* Change the link color on hover */
li a:hover {
  background-color: #555;
  color: white;
}
.txt{
  width: 300px;
}
.info{
  text-align: center;
  font-size: larger;
}
.warnning{
  text-align: center;
  font-size: larger;
}
.pbin{
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

}
.img {
  height: 25px;
  width: 25px;
  border-radius: 10px;
}
.type{
 color: rgb(255, 58, 58);
}
.type1{
  color: gold;
 }
.inline{
  display: inline-flex;
  gap: 10px;
}
.dark {
  background-color: #333;
color: #fff;
  }
  .light {
  background-color: #fff;
  color: #333;
  }
.d404{
  display: flex;
  align-items: center;
  flex-direction: column;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  margin-top: 20%;
  text-align: center;
}
.s{
  font-size: 40px;
}

.tbin{
  height: auto;
  display: flex;
  margin-left: 330px;
  align-items: center;
  flex-direction: column;
}
.ta{
  display: block;
  margin-left: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: none;
  width: 600px;
  height: 600px;
  resize: none;
  border-radius: 5px;
  background-color: #1e2024;
  color: white;
}
.cardd{
  font-size: 14px;
  max-height: 1100px;
  width: 400px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   border-radius: 10px;
   display: block;
   margin:auto;
  }
.ca{
 font-size: larger;
 background-color: white;
 border:none;
 width: 100px;
 
}

.ca:hover{
  background-color: rgb(219, 219, 219);
}
.ms{
  display: block;
  margin: auto;
  text-align: center;
}
.imgg{
  margin: auto;
  display: block;
  max-width: 500px;
  max-height: 400px;
}
.svg{
  width: 25px;
  height: 25px;
  background-color: #2f3136;
  border-radius: 15px;
}
.vercode{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  outline: none;
  width: 320px;
  height: 45px;
  border: none;
  border-radius: 10px;
  font-size: large;
}
.vercode:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.snippet{
  display: block;
  margin: auto;
  border: none;
  width: 400px;
  height: 400px;
  resize: none;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  outline: none;
  
}
.in{
  outline: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  font-size: 15px;
  width: 150px;
  border: none;
  border-radius: 5px;
  color: white;
  height: 35px;
  background: rgba(128, 128, 128, 0.096);
}
.in:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.p{
  height: auto;
  display: flex;
  align-items: left;
  flex-direction: column;
text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.mbtn {
  font-family: arial;
  color: #000000 !important;
  font-size: 18px;
  text-shadow: 1px 1px 0px #7CACDE;
  box-shadow: 1px 1px 10px #6D9CA1;
  padding: 10px 5px;
  border-radius: 24px;
  border: 0px solid #3866A3;
  background: #00FF33;
}
.mbtn:hover {
  color: #000000 !important;
  background: #28D60D;
}
.fields{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6%;

}
.bg{
  width: 100%;
 
}
.box{
  position: relative;
  display: inline-block; /* Make the width of box same as image */
}
.box .text{
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%; /* Adjust this value to move the positioned div up and down */
  text-align: center;
  width: 60%; /* Set the width of the positioned div */
}
.border5 {
    border-radius: 3px;
    background-color: white;
    color: black;
    border: 2px solid #555555;
}
.btn1 {
    background-color: #20242000;
    color: #FFFFFF;
    padding: 15px 32px;

    text-align: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin: 16px 0 !important;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}
.border5:hover{
  background: #3f3f3f;
  border: rgb(85, 85, 85);
  outline: 10px;
}
